

/* Main Container for Form and Image */
.contact2-form-page {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  background-color: white;
  margin-bottom: 3rem;
  
 
}
.contact-form-main2{
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  gap: 2rem;
  padding: 2rem;
  margin-left: 2rem;

}

/* Form Heading Alignment */
.contact2-form-heading h2 {
  text-align: center;
  margin-bottom: 20px;
}

/* Image Container for Animation */
.contact2-image-container img {
  max-width: 500px;
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
}

/* Form Styling */
.contact2-inquiry-form {
  max-width: 450px;
}

.contact2-form-group,
.contact2-form-group-inline {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.contact2-form-group-inline {
  flex-direction: row;
  gap: 10px;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

input:focus,
select:focus,
textarea:focus {
  border-color: black;
}

/* Phone Input Styling */
.contact2-form-group-phone {
  flex: 1;
}

.react-tel-input .form-control {
  width: 100%;
  border-radius: 4px;
}

/* Button Styling */
.contact2-button-container {
  text-align: center;
}

.contact2-submit-btn {
  width: 50%;
  padding: 10px;
  font-size: 18px;
  background-color: white;
  color: black;
  border: 2px solid #ef6101;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact2-submit-btn:hover {
  background-color: #ef6101;
  color: white;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .contact2-form-page {
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
  }

  .contact2-image-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .contact2-image-container img {
    max-width: 100%;
    height: auto;
  }
}
