.about-3-cont-main{
    display: block;
    unicode-bidi: isolate;
    margin-bottom: 3rem;
}
.about-3-cont {
    border: 1.5rem solid #153c5b;
    padding: 0 2rem 2rem;
}
.about-3-cont h1 {
    font-family: 'Roboto', sans-serif;
    opacity: 1;
    text-align: left;
    color: #b6351b;
    letter-spacing: .42px;
    font-size: 2rem;
    font-weight: bold;
}

.about-3-cont p{
    color: #000;
    font-family: Helvetica;
    font-size: 1.1rem;
    letter-spacing: 0;
    opacity: 1;
    width: 60%;
    
}

@media (max-width: 768px){
    .about-3-cont-main{
        display: block;
        unicode-bidi: isolate;
        margin-bottom: 3rem;
    }
    .about-3-cont {
        border: 1.5rem solid #153c5b;
        padding: 0 2rem 2rem;
    }
    .about-3-cont h1 {
        color: #b6351b;
        font-family: 'Roboto', sans-serif;
        letter-spacing: .42px;
        opacity: 1;
        text-align: left;
        width: 100%;

    }
    
    .about-3-cont p{
        color: #000;
        font-family: Helvetica;
        font-size: 1rem;
        letter-spacing: 0;
        opacity: 1;
        width: 100%;
        
    }
    
}