.slider-container-2{
    overflow: hidden;
    margin-left: 2rem;
}
.Testimonials_container__KNLz4{
    margin-left: 5rem;
    padding-bottom: 3rem;
    width: 100%;
}
.Testimonials_card__1niE4{
    border: 1px solid #234f7b;
    height: 10rem;
    opacity: 1;
    padding: 1rem;
    width: 55rem;
}
.Testimonials_card__1niE4 h4{
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}
.Testimonials_card__1niE4 img{
    margin-top: -1rem;
    width: 7rem;
}
@media (max-width: 768px){
    .slider-container-2{
        overflow: hidden;
        margin-left: 0rem;
    }
    .Testimonials_container__KNLz4{
        margin-left: 5rem;
        padding-bottom: 3rem;
        width: 100%;
    }
    .Testimonials_card__1niE4{
        border: 1px solid #234f7b;
        height: 15rem;
        opacity: 1;
        padding: .5rem;
        width: 13rem;
        font-size: .8rem;
    }
    .Testimonials_card__1niE4 h4{
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
    }
    .Testimonials_card__1niE4 img{
        margin-top: -1rem;
        width: 5rem;
   
    }
}
