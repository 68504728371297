.about-4-main-cont{
    margin-bottom: 3rem;
    margin-left: 3rem;
}
.about-4-main-cont h1{
    color: #b6351b;
    font-family: 'Roboto', sans-serif;
    letter-spacing: .42px;
    opacity: 1;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
}
.about-4-main-cont p{
    color: #000;
    font-family: Helvetica;
    font-size: 1rem;
    letter-spacing: 0;
    opacity: 1;
}
.about-4-logo{
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    letter-spacing: 0;
    opacity: 1;
    text-align: left;
}
.about-4-logo i{
    color: #234f7b;
    margin-right: .5rem;
}