.choose-service{
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    width: 100%;
}
.choose-service h1{
    color: #b6351b;
    font-size: 1.7rem;
    letter-spacing: 1px;
    margin-left: 3rem;
    opacity: 1;
    text-align: left;
}
.choose-service p{
    color: #333;
    font-family: Helvetica;
    font-size: 1rem;
    letter-spacing: 0;
    margin-bottom: 1rem;
    margin-left: 3rem;
    margin-top: -.5rem;
    opacity: 1;
    text-align: left;
}
.choose-service-1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}


.choose-image {
    width: 80px; /* Adjust the size of the circle */
    height: 80px;
    display: flex;
    box-shadow: 0 3px 6px #00000029;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Makes the container circular */
    overflow: hidden; /* Ensures the image doesn't overflow */
    margin-bottom: 0.5rem; /* Adds space between the image and the text */
    
}
.choose-service-1 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; /* Prevents wrapping to a new line */
    justify-content: space-around; /* Ensures equal spacing between boxes */
    width: 100%;
}

.choose-box {
    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    opacity: 1;
    padding: 1rem;
    text-align: center; 
    width: 11rem;
    border-radius: 50%;
    margin: 0 1rem; /* Adds horizontal spacing between boxes */
}


.choose-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the circle nicely */
}

.choose-box h2 {
    color: #204c79;
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 2rem;
    opacity: .98;
    text-align: center;
    text-transform: capitalize;
    width: 13rem;
}
@media (max-width: 768px){
    .choose-service{
        display: flex;
        flex-direction: column;
        font-family: 'Roboto', sans-serif;
        margin-top: 2rem;
        width: 100%;
    }
    .choose-service h1{
        color: #b6351b;
        font-size: 1.7rem;
        letter-spacing: 1px;
        margin-left: 2rem;
        opacity: 1;
        text-align: left;
    }
    .choose-service p{
        font-size: .9rem;
        margin-left: 2rem;
    }
    .choose-service-1{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
 
    
    .choose-image {
        border-radius: 50%;
        box-shadow: 1px 1px 2px 1px rgba(70, 96, 143, .5);
        width: 5rem;
        
    }
    .choose-service-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap; /* Prevents wrapping to a new line */
        justify-content: space-around; /* Ensures equal spacing between boxes */
        width: 100%;
    }
    
    .choose-box {
        align-items: center;
        background: #fff;
        display: flex;
        flex-direction: column;
        opacity: 1;
        padding: 1rem;
        text-align: center; 
        width: 11rem;
        border-radius: 50%;
        margin: 0 1rem; /* Adds horizontal spacing between boxes */
    }
    
    
    .choose-image img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensures the image fills the circle nicely */
    }
    
    .choose-box h2 {
        color: #204c79;
        font-family: 'Roboto', sans-serif;
        font-size: 1.1rem;
        font-weight: 500;
        margin-top: 2rem;
        opacity: .98;
        text-align: center;
        text-transform: capitalize;
        width: 13rem;
    }
}