.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    max-width: 100%;
}

.image-container {
    margin-left: 16px;
}

.image-container img {
    width: 11rem;
    height: 10rem;
}

.burger {
    display: none; /* Hide burger icon by default */
    flex-direction: column;
    cursor: pointer;
}

.bar {
    width: 30px;
    height: 3px;
    background-color: darkblue; /* Burger icon color */
    margin: 5px 0;
    transition: all 0.3s ease;
}

.menu {
    display: flex; /* Default to flex display */
}

.list-container {
    display: flex;
    list-style: none;
    gap: 4rem;
    margin-right: 5rem;
}

.list-container li {
    font-size: 1.3rem;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    position: relative;
}

/* Default link styles */
.list-container li a {
    text-decoration: none;
    color: #969696; /* Faint blue */
    transition: color 0.3s ease;
}

.list-container li a.selected {
    color: #063970; /* Dark blue */
    font-weight: bold;
    z-index: 1; /* Brings it to the front */
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.1); /* Slight shadow effect */
}

/* Hover effect for links */
.list-container li:hover a {
    color: #153c5b; /* Hover color for all links */
}

.list-container li::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background: orange;
    transition: width 0.3s ease-in-out;
    position: absolute;
    bottom: -5px;
    left: 0;
}

.list-container li:hover::after {
    width: 100%;
}

/* Responsive styles */
@media (max-width: 768px) {
    .nav-container {
        display: flex;
        font-family: 'Roboto', sans-serif;
        align-items: center;
        background-color: #fff;
        max-width: 100%;
       
    }
    
    .burger {
        display: flex; /* Show burger icon on small screens */
        margin-right: 2rem;
    }

    .menu {
        position: absolute;
        top: 7rem;
        left: -100%; /* Hide menu off-screen */
        height: 40vh;
        width: 100%;
        background-color:  #153c5b;; /* Menu background */
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
        transition: left 0.3s ease;
        z-index: 1000;
    }

    .menu.open {
        left: 0; /* Show menu when open */
    }

    .list-container {
        flex-direction: column;
        padding: 0.5rem;
        margin-left: 2rem;
        display: flex;
        gap: 2.5rem;
       
        
    }

    .list-container li {
        margin: 2px 0; /* Space between links */
        
        font-weight: bold;
    }
    .menu.open .list-container li a {
        color: white; /* Color for burger menu items */
       
        font-size: 1rem;
    }
}
