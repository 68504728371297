.about-2-main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: 'Roboto', sans-serif;
    gap: 0;
    justify-content: space-around;
    margin-bottom: 3rem;
}
.about-2-card{
    align-items: center;
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 10px;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    height: 14rem;
    opacity: 1;
    padding: 1rem;
    text-align: left;
    width: 11rem;
}
.about-2-card img{
    padding: 1rem;
    width: 65px;
    height: 65px;

}
.about-2-card h2{
    color: #204c79;
    font-size: 1.2rem;
    font-weight: 500;
    opacity: .98;
    text-align: left;
    text-transform: capitalize;
    display: block;
}
.about-2-card p{
    color: #333;
    font-size: .8rem;
    font-weight: 600;
    margin-top: -.5rem;
    text-align: center;

}
@media (max-width: 768px) {
    .about-2-main{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-family: 'Roboto', sans-serif;
        gap: 2rem;
        justify-content: space-around;
        margin-bottom: 3rem;
    }
    .about-2-card{
        align-items: center;
        background: #fff 0 0 no-repeat padding-box;
        border-radius: 10px;
        box-shadow: 0 3px 6px #00000029;
        display: flex;
        flex-direction: column;
        height: 14rem;
        opacity: 1;
        padding: 1rem;
        text-align: left;
        width: 11rem;
    }
    .about-2-card img{
        padding: 1rem;
    width: 4rem;
    
    }
    .about-2-card h2{
        color: #204c79;
        font-size: 1.1rem;
        font-weight: 500;
        opacity: .98;
        text-align: left;
        text-transform: capitalize;
    }
    .about-2-card p{
        color: #333;
        font-size: .8rem;
        font-weight: 500;
        margin-top: -.5rem;
        text-align: center;
    }
}