.homecomp3-main{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 2rem;
    width: 100%;
}
.homecomp3-feature{
    margin-left: 3rem;

}
.homecomp3-feature h2{
    color: #b6351b;
    font-family: 'Roboto', sans-serif;
    font-size: 1.7rem;
    letter-spacing: .42px;
    opacity: 1;
    text-align: left;

}
.homecomp3-feature p{
    color: #333;
    font-family: Helvetica;
    margin-top: -.5rem;
}
.homecomp3-btn{
    margin-right: 5rem;
}
.homecomp3-btn a {
    border: 2px solid #b6351b;
    border-radius: 1rem;
    color: #204c79;
    font-size: 1.1rem;
    font-weight: 700;
    padding: .7rem 2rem;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

.homecomp3-btn a:hover {
    background-color: #204c79; /* Blue background */
    color: #ffffff; /* White text */
    border: none;
}
@media (max-width: 768px){
    .homecomp3-main{
        display: flex;
        flex-direction: column;
        justify-content: left;
        text-align: left;
        width: 100%;
    }
    .homecomp3-feature{
        margin-left: 2rem;
    
    }
    .homecomp3-feature h2{
        color: #b6351b;
        font-family: 'Roboto', sans-serif;
        font-size: 1.7rem;
        letter-spacing: .42px;
        opacity: 1;
        text-align: left;
    
    }
    .homecomp3-feature p{
        color: #333;
        font-family: Helvetica;
        margin-top: -.5rem;
        font-size: .9rem;
    }
    .homecomp3-btn {
        align-self: flex-start; /* Aligns the button container to the left */
        margin-left: 2rem; /* Adds left margin for alignment */
        margin-right: 0;
    }

    .homecomp3-btn a{
        border: 2px solid #b6351b;
        border-radius: 1rem;
        color: #204c79;
        font-size: .9rem;
        font-weight: 700;
        padding: .3rem .7rem;
        text-decoration: none;
        transition: .3s;
    }
}