.main-footer-cont{
    background-image: linear-gradient(150deg, #fff 37%, #fff 0, #153c5b 0);
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;

    max-width: 100%;
    
}
.main-foot{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    
}
.foot-left{
    display: flex;
    flex-direction: row;
    width: 50%;
    unicode-bidi: isolate;

}
.foot-left img{
    margin-left: 1rem;
    width: 10rem;
    overflow: clip;
}
.foot-left-1{
    display: block;
    unicode-bidi: isolate;
}

.foot-left-1 p {
    color: #333;
    font-size: 0.9rem;
    letter-spacing: 0;
    opacity: 1;
    text-align: left;
    width: 16rem;
}
.foot-icon{
    display: flex;
    unicode-bidi: isolate;
}
.foot-icon img{
    height: 1.5rem;
    margin-right: -.5rem;
    width: 1.5rem;
    margin-left: 1rem;
}
.foot-icon a{
    cursor: pointer;
    text-decoration: underline;
}
.foot-right{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
    unicode-bidi: isolate;

}
.foot-1{
    display: block;
    unicode-bidi: isolate;

}
.foot-1 a{
    text-decoration: none;
    color: #fff;
}
.foot-1 h3{
    color: #fff;
    font-size: 1rem;
    letter-spacing: .3px;
    opacity: 1;
    text-align: left;
    font-size: 1.17rem;
    font-weight: bold;
}

.foot-1 p{
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0;
    opacity: 1;
    text-align: left;
}
.foot-bottom{
    display: block;
    unicode-bidi: isolate;
    background-color: transparent;
    border-top: 1px solid #fff;
}
.foot-bottom p{
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0;
    margin-right: 4rem;
    opacity: 1;
    text-align: right;
}
.Footer_copyright{
    color: #fff;
    text-decoration: none;

}
@media (max-width: 768px) {
    .main-footer-cont{
        display: flex;
        flex-direction: column;
        width: 100%;
        background-image: linear-gradient( #fff 37%, #fff 0, #153c5b 0)

    }
    .main-foot{
        align-items: center;
        flex-direction: column;
        justify-content: center;
        
    }
    .foot-left{
        background-color: #fff;
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        width: 100%;
    
    }
    .foot-left img{
        margin-left: 0;
        width: 8rem;
    }
    .foot-left-1{
        display: block;
        unicode-bidi: isolate;
    }
    
    .foot-left-1 p {
        color: #333;
        font-family: 'Roboto', sans-serif;
        font-size: .7rem;
        letter-spacing: 0;
        opacity: 1;
        text-align: left;
        width: 100%;
    }
    .foot-icon{
        display: flex;
        margin-left: 0;
    }
    .foot-icon img{
        height: 1.5rem;
        margin-bottom: 1rem;
        margin-right: .5rem;
        width: 1.5rem;
    }
    .foot-icon a{
        cursor: pointer;
        text-decoration: underline;
    }
    .foot-right{
        background-color: #153c5b;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    
    }
    .foot-1{
        display: block;
        unicode-bidi: isolate;
    
    }
    .foot-1 a{
        text-decoration: none;
        color: #fff;
    }
    .foot-1 h3{
        color: #fff;
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        letter-spacing: .3px;
        opacity: 1;
        text-align: left;
    }
    
    .foot-1 p{
        color: #fff;
        font-family: 'Roboto', sans-serif;
        font-size: .8rem;
        letter-spacing: 0;
        opacity: 1;
        text-align: left;
    }
    .foot-bottom{
        border-top: 1px solid #fff;
    }
    .foot-bottom p{
        color: #fff;
        display: flex;
        font-family: 'Roboto', sans-serif;
        font-size: .5rem;
        justify-content: center;
        letter-spacing: 0;
        opacity: 1;
        width: 100%;
    }
    .Footer_copyright{
        color: #fff;
        text-decoration: none;
    
    }
    
    
}