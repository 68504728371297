.careercomp1-main{
    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: row;
    font-family: 'Roboto', sans-serif;
    justify-content: space-around;
    padding: 1rem;
    margin-bottom: 3rem;
}


.comp1-head-p{
    color: #ba2f13;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: -2rem;
}
.careercomp1-info h1{
    color: #12477b;
    font-size: 3.5rem;
    font-family: Arial, Helvetica, sans-serif;
}
.comp1-bottom-1{
    font-size: 1.3rem;
    margin-top: -2rem;
    width: 25rem;

}
.careercomp1-info button{
    background: #12477b;
    border: 1.5px solid #12477b;
    border-bottom-left-radius: 2rem 2rem;
    border-top-right-radius: 2rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1px;
    padding-inline-start: 1rem;
    padding: 1rem 2rem
}
.careercomp1-info a{
    color: #fff;
    text-decoration: none;
}
.carrercomp1-banner img{
    width: 40rem;
}
@media (max-width: 768px) {
    .careercomp1-main{
        align-items: center;
        background: #fff;
        display: flex;
        flex-direction: column;
        font-family: 'Roboto', sans-serif;
        justify-content: space-around;
        padding: 1rem;
       overflow: hidden;
    }
    
    
    .comp1-head-p{
        color: #ba2f13;
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: -2rem;
        width: 95%;
    }
    .careercomp1-info h1{
        color: #12477b;
        font-size: 3.4rem;
        font-family: Arial, Helvetica, sans-serif;
    }
    .comp1-bottom-1{
        font-family: 'Roboto', sans-serif;
        width: 95%;
    
    }
    .careercomp1-info button{
        background: #12477b;
        border: 1.5px solid #12477b;
        border-bottom-left-radius: 2rem 2rem;
        border-top-right-radius: 2rem 2rem;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 1px;
        padding-inline-start: 1rem;
        padding: 1rem 2rem;
    }
    .careercomp1-info a{
        color: #fff;
        text-decoration: none;
    }
    .carrercomp1-banner img{
        width: 95%;
        
    }
}