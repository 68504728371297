.blogcomp-main{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 2rem;
}
.blogcomp-heading{
    color: #b6351b;
    font-family: 'Roboto', sans-serif;
    font-size: 2.1rem;
    font-weight: bold;
}
.blogcomp-card-container{
    gap: 5rem;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.blogcomp-card{
    border: 1px double #b6351b;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 28rem;
    overflow: hidden;
    padding-bottom: 1rem;
    width: 22rem;

}
.blogcomp-image img{
    height: 100%;
    position: relative;
    width: 100%;

}
.blogcomp-card-heading{
    color: #204c79;
    font-family: 'Roboto', sans-serif;
    opacity: .98;
    padding-left: .7rem;
    position: relative;
    text-align: left;
    text-transform: capitalize;
    font-size: 1rem;
    width: 100%;

}
.blogcomp-card-sub{
    font-size: .8rem;
    color: #204c79;
    font-family: 'Roboto', sans-serif;
    opacity: .98;
    padding-left: .7rem;
    position: relative;
    text-align: left;
    text-transform: capitalize;
}
.blogcomp-card a{
    background: transparent;
    border: .3px double #b6351b;
    border-radius: 10px;
    color: #b6351b;
    font-size: .81rem;
    font-style: "Roboto,sans-serif";
    font-weight: 700;
    margin-left: .5rem;
    margin-top: -.5rem;
    padding: .4rem;
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 5rem;
    cursor: pointer;

}
@media (max-width: 768px) {
    .blogcomp-main{
        box-sizing: border-box;
        padding: .5rem;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }
    .blogcomp-heading{
        color: #b6351b;
        font-family: 'Roboto', sans-serif;
        font-size: 2.1rem;
        font-weight: bold;
    }
    .blogcomp-card-container{
        gap: 5rem;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .blogcomp-card{
        height: 24rem;
        width: 16rem;
        border: 1px double #b6351b;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    overflow: hidden;
    padding-bottom: 1rem;
    
    }
    .blogcomp-image img{
        height: 100%;
        position: relative;
        width: 100%;
    
    }
    .blogcomp-card-heading{
        color: #204c79;
        font-family: 'Roboto', sans-serif;
        opacity: .98;
        padding-left: .7rem;
        position: relative;
        text-align: left;
        text-transform: capitalize;
        font-size: 1rem;
    width: 100%;
    
    }
    .blogcomp-card-sub{
        font-size: .8rem;
        color: #204c79;
        font-family: 'Roboto', sans-serif;
        opacity: .98;
        padding-left: .7rem;
        position: relative;
        text-align: left;
        text-transform: capitalize;
    }
    .blogcomp-card a{
        background: transparent;
        border: .3px double #b6351b;
        border-radius: 10px;
        color: #b6351b;
        font-size: .8rem;
        font-style: "Roboto,sans-serif";
        font-weight: 700;
        margin-left: .5rem;
        margin-top: -.5rem;
        padding: .4rem;
        position: relative;
        text-align: left;
        text-decoration: none;
        width: 5rem;
        cursor: pointer;
    
    }
}