.slider-container-1{
    overflow: hidden;

    padding-bottom: 3rem;
    margin-left: 3rem;
}

.slick-dots {
    bottom: 0; /* Adjust vertical positioning */
    display: flex; /* Align dots horizontally */
    justify-content: center; /* Center the dots */
    left: 0; /* Align left */
    list-style: none; /* Remove bullet points */
    margin: 0; /* Remove margin */
    padding: 0; /* Remove padding */
    position: absolute; /* Positioning relative to the slider */
    right: 0; /* Align right */
    text-align: center; /* Center align text */
}

/* Individual dot button styles */
.slick-dots li button {
    border: 2px solid grey; /* Border around dots */
    border-radius: 50%; /* Make dots circular */
    box-shadow: none; /* Remove shadow */
    cursor: pointer; /* Change cursor to pointer */
    display: inline-block; /* Block display for dots */
    height: 12px; /* Height of dots */
    margin: 0 6px 0 0; /* Spacing between dots */
    opacity: 1; /* Fully opaque */
    outline: 0; /* Remove outline */
    padding: 0; /* Remove padding */
    transition: background .5s; /* Smooth background transition */
    width: 12px; /* Width of dots */
}

/* Active dot styles */
.slick-dots li.slick-active button {
    background: #080808; /* Background color for active dot */
}
/* Remove any unwanted pseudo-elements */
.slick-dots li::before,
.slick-dots li::after {
    content: none; /* Remove pseudo-elements */
}

/* Set margins and padding to zero */
.slick-dots li {
    margin: 0; /* Remove margins */
    padding: 0; /* Remove padding */
}

/* Adjust positioning if necessary */
.slick-dots {
    position: relative; /* Ensure the dots are positioned correctly */
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height:0;
    
    text-align: center;
    opacity: .25;
    color: black;

}
.react-multiple-carousel__arrow {
    background: black; /* Solid black background */
    border: none;
    border-radius: 50%; /* Circular shape */
    cursor: pointer;
    width: 2.688rem;
    height: 2.688rem;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    position: absolute;

    z-index: 1000;
}

.react-multiple-carousel__arrow.slick-prev:before {
    content: "←"; /* Left arrow icon */
    color: white;
    font-size: 20px;
    display: block;
}

.react-multiple-carousel__arrow.slick-next:before {
    content: "→"; /* Right arrow icon */
    color: white;
    font-size: 20px;
    display: block;
}

.react-multiple-carousel__arrow.slick-prev {
    left: 10px;
}

.react-multiple-carousel__arrow.slick-next {
    right: 10px;
}

/* Hover and active styles to keep buttons visible */
.react-multiple-carousel__arrow:hover,
.react-multiple-carousel__arrow:focus,
.react-multiple-carousel__arrow:active {
    background: black;
    color: white;
}

/* Prevents arrow from disappearing when clicked */
.react-multiple-carousel__arrow:active:before,
.react-multiple-carousel__arrow:focus:before {
    color: white;
    opacity: 1;
}



.viewallcomp2-cont-1-1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.viewallcomp2-card-cont-1-1{
    gap: 0;
    padding-bottom: 2rem;
}
.viewallcomp2-card-1-1{
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 10px;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    opacity: 1;
    width: 24rem;
}
.viewallcomp2-image-cont-1-1 img{
    background-size: cover;
    border: 1px solid #707070;
    border-radius: 8px;
    height: 18rem;
    opacity: 1;
    width: 24rem;
}
.viewallcomp2-card-info-1-1{
    padding: 1rem;
}
.viewallcomp2-card-info-1-1 p{
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0;
    opacity: .98;
    text-align: left;
    text-transform: capitalize;
}
.viewallcomp2-card-info-1-1 h3{
    color: #204c79;
    font-size: 1.4rem;
    margin-bottom: -.4rem;
    margin-top: -.4rem;
}
.viewallcomp2-card-subheading-1-1{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}
.viewallcomp2-card-subheading-1-1 p{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;
    letter-spacing: 0;
    opacity: .98;
    text-align: left;
    text-transform: capitalize;
}
.viewallcomp2-card-info-1-1 link {
    color: #ff7e00; /* Bright color for visibility */
    font-weight: bold; /* Make it stand out */
    text-decoration: none; /* Remove underline */
    padding: 10px; /* Add some padding */
    border: 2px solid #ff7e00; /* Border to enhance visibility */
    border-radius: 5px; /* Slightly rounded corners */
    transition: background-color 0.3s, color 0.3s; /* Transition effect */
}

/* Hover effect */
.price-link:hover {
    color: #ff7e00; /* Background color on hover */

}
.price-link {
    color: #204c79;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    opacity: .98;
    text-align: left;
    text-decoration: none;
    text-transform: capitalize;
}


    @media (max-width: 768px) {
      
        .slider-container-1{
            margin-left: 2rem;
        }
        
        .slick-dots {
            bottom: 0; /* Adjust vertical positioning */
            display: flex; /* Align dots horizontally */
            justify-content: center; /* Center the dots */
            left: 0; /* Align left */
            list-style: none; /* Remove bullet points */
            margin: 0; /* Remove margin */
            padding: 0; /* Remove padding */
            position: absolute; /* Positioning relative to the slider */
            right: 0; /* Align right */
            text-align: center; /* Center align text */
        }
        
        /* Individual dot button styles */
        .slick-dots li button {
            border: 2px solid grey; /* Border around dots */
            border-radius: 50%; /* Make dots circular */
            box-shadow: none; /* Remove shadow */
            cursor: pointer; /* Change cursor to pointer */
            display: inline-block; /* Block display for dots */
            height: 12px; /* Height of dots */
            margin: 0 6px 0 0; /* Spacing between dots */
            opacity: 1; /* Fully opaque */
            outline: 0; /* Remove outline */
            padding: 0; /* Remove padding */
            transition: background .5s; /* Smooth background transition */
            width: 12px; /* Width of dots */
        }
        
        /* Active dot styles */
        .slick-dots li.slick-active button {
            background: #080808; /* Background color for active dot */
        }
        /* Remove any unwanted pseudo-elements */
        .slick-dots li::before,
        .slick-dots li::after {
            content: none; /* Remove pseudo-elements */
        }
        
        /* Set margins and padding to zero */
        .slick-dots li {
            margin: 0; /* Remove margins */
            padding: 0; /* Remove padding */
        }
        
        /* Adjust positioning if necessary */
        .slick-dots {
            position: relative; /* Ensure the dots are positioned correctly */
        }
        .slick-dots li button:before {
            font-family: 'slick';
            font-size: 0;
            line-height: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height:0;
            content: '•';
            text-align: center;
            opacity: .25;
            color: black;
        
        }
        .react-multiple-carousel__arrow.slick-prev {
            left: 1px;
        }
        
        
        .viewallcomp2-cont-1-1{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-right: 0.8rem;
        }
        .viewallcomp2-card-cont-1-1{
        
            padding-bottom: 1rem;
        }
        .viewallcomp2-card-1-1{
            background: #fff 0 0 no-repeat padding-box;
            border-radius: 1rem;
            box-shadow: 0 3px 6px #00000029;
            display: flex;
            flex-direction: column;
            opacity: 1;
            width: 20rem;
        }
        .viewallcomp2-image-cont-1-1 img{
            background-size: cover;
            border: 1px solid #707070;
            border-radius: 8px;
            height: 16rem;
            opacity: 1;
            width: 20rem;
        }
        .viewallcomp2-card-info-1-1{
            padding: 2rem;

        }
        .viewallcomp2-card-info-1-1 p{
            font-family: 'Roboto', sans-serif;
            letter-spacing: 0;
            opacity: .98;
            text-align: left;
            text-transform: capitalize;
        }
        .viewallcomp2-card-info-1-1 h3{
            color: #204c79;
            font-size: 1.2rem;
            margin-bottom: -.2rem;
            margin-top: -.2rem;
        }
        .viewallcomp2-card-subheading-1-1{
            display: flex;
            flex-direction: row;
            gap: 1.5rem;
        }
        .viewallcomp2-card-subheading-1-1 p{
            color: #000;
            font-family: 'Roboto', sans-serif;
            font-size: .8rem;
            letter-spacing: 0;
            opacity: .98;
            text-align: left;
            text-transform: capitalize;
        }
        
}