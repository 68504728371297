.about-1-cont-main{
    margin-bottom: 3rem;
    display: block;
    unicode-bidi: isolate;
}
.about-1-cont {
    border:solid #b6351b;
    padding:0 2rem 2rem ;
    border-width: 1.5rem;
    display: block;
    unicode-bidi: isolate;
}
.about-1-cont h1 {
    color: #b6351b;
    font-family: 'Roboto', sans-serif;
    letter-spacing: .42px;
    opacity: 1;
    text-align: left;
}

.about-1-cont h3 {
    display: block;
    font-size: 1.20em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

.about-1-cont p {
    color: #000;
    font-family: Helvetica;
    font-size: 1.1rem;
    letter-spacing: 0;
    opacity: 1;
    width: 60%;
    
}
@media (max-width: 768px) {
    .about-1-cont-main{
        margin-bottom: 3rem;
        display: block;
        unicode-bidi: isolate;
    }
    .about-1-cont {
        border:solid #b6351b;
        padding:0 2rem 2rem ;
        border-width: 1.5rem;
        display: block;
        unicode-bidi: isolate;
    }
    .about-1-cont h1 {
        color: #b6351b;
        font-family: 'Roboto', sans-serif;
        letter-spacing: .42px;
        opacity: 1;
        text-align: left;
        width: 100%;
    }
    
    .about-1-cont h3 {
        display: block;
        font-size: 1.20em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
    }
    
    .about-1-cont p {
        color: #000;
        font-family: Helvetica;
        font-size: 1rem;
        letter-spacing: 0;
        opacity: 1;
        width: 100%;
    }

}
