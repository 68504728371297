.nav-container-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent; /* Make background transparent to see the image */
    max-width: 1500px;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Roboto', sans-serif;
    width: 100%;
  }
  
  .image-container-1 {
    margin-left: 16px;
  }
  
  .image-container-1 img {
    width: 11rem;
    height: 10rem;
  }
  
  .burger-menu-1 {
    display: none; /* Hidden by default */
    cursor: pointer;
  }
  
  .menu-1 {
    display: flex; /* Default display */
  }
  
  .list-container-1 {
    display: flex;
    list-style: none;
    gap: 4rem;
    margin-right: 5rem;
  }
  
  .list-container-1 li {
    font-size: 1.3rem;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    position: relative;
  }
  
  /* Default styling for links */
.list-container-1 li a {
  text-decoration: none;
  color: #c0c0c0; /* Faint grey */

  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

/* Underline on hover */
.list-container-1 li:hover a::after {
  color: #fff; /* Change to white on hover */
}

/* When a link is selected, apply the active class */
.list-container-1 li a.active {
  color: #fff; /* White color when active */
  transform: scale(1.05); /* Slight enlargement effect */
  z-index: 2; /* Bring the selected link to the front */
  font-weight: bold;
}

/* Underline effect */
.list-container-1 li::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background: orange;
  transition: width 0.3s ease-in-out;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.list-container-1 li:hover::after,
.list-container-1 li a.active::after {
  width: 100%;
}

  
  .list-container-1 li::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background: orange;
    transition: width 0.3s ease-in-out;
    position: absolute;
    bottom: -5px;
    left: 0;
  }
  
  .list-container-1 li:hover::after {
    width: 100%;
  }
  
  .image-container-2 {
    width: 100%;
    height: 40rem;
  }
  
  .image-container-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .about-text-2 {
    color: #fff;
    font-family: 'Roboto', sans-serif;
    left: 2rem;
    position: absolute;
    top: 30%;
   
  }
  
  .p1-1{
    font-size: 1.7rem;
    letter-spacing: .32px;
    line-height: 0;
    margin-bottom: -1rem;
    color: #fff;
    opacity: 1;
    text-align: left;
    text-shadow: 0 6px 6px #00000029;
    margin-top: 1.5rem;
   
 
  }
  .p2-1{
    font-family: Arial;
    font-size: 7.2rem;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: .5rem;
    color: #fff;
    opacity: 1;
    text-align: left;
    text-shadow: 0 6px 6px #00000029;
    margin-top: 5.75rem;
    margin-bottom: 5.75rem;
  }
  .p3-1{
    color: #fff;
    letter-spacing: .4px;
    opacity: 1;
    text-align: left;
    font-size: 1.9rem;
    line-height: 0;
    text-shadow: 0 6px 6px #00000029;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .p4-1 {
    color: #fff;
    letter-spacing: .4px;
    opacity: 1;
    text-align: left;
    font-size: 1.7rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.p4bg-1 {
 
  background-color: rgba(223, 112, 38, 0.7); /* Slightly transparent orange */
  animation: transparentBlinkEffect 3s infinite; /* Slow blink effect */
}

@keyframes transparentBlinkEffect {
  0%, 100% {
      background-color: rgba(223, 112, 38, 0); /* Fully transparent */
  }
  50% {
      background-color: rgba(223, 112, 38, 0.7); /* Slightly visible */
  }
}



  .b1-1 {
    background: none;
    border: 1px solid #ef6101;
    border-radius: 0.9rem;
    color: #fff;
    margin-top: 1.5rem;
    opacity: 1;
    padding: 1rem 2rem;
    text-align: left;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.b1-1 a {
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    text-decoration: none;
    transition: color 0.3s ease; /* Smooth transition */
}

.b1-1:hover {
    background-color: #fff; /* White background on hover */
    color: #ef6101; /* Orange text on hover */
}

.b1-1:hover a {
    color: #ef6101; /* Change link color inside button to orange on hover */
}
  /* Responsive styles for screens smaller than 768px */
  @media (max-width: 768px) {
    /* Burger menu */
    .burger-menu-1 {
      display: block; /* Show burger menu on small screens */
      cursor: pointer;
      margin-right: 2rem;
    }
  
    /* Responsive menu styling */
    .menu-1 {
      position: absolute;
      top: 5rem;
      left: -100%;
      height: 30vh;
      width: 80%;
      background-color: #153c5b;
      transition: left 0.3s ease;
      z-index: 1000;
    }
  
    .menu-1.open-1 {
      left: 0;
    }
  
    /* List container in menu */
    .list-container-1 {
      flex-direction: column;
      gap: 1.5rem;
      padding: 1rem;
      margin-left: 1rem;
    }
  
    .list-container-1 li a {
      color: white;
      font-size: 1rem;
      font-weight: 600;
    }
  
    /* Navbar container */
    .nav-container-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: transparent; /* See background image */
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      font-family: 'Roboto', sans-serif;
      z-index: 10;
    }
  
    /* Image container */
    .image-container-1 {
      margin-left: 12px;
    }
  
    .image-container-1 img {
      width: 8rem;
      height: auto;
    }
  
    /* About section text */
    .about-text-2 {
      color: #fff;
      font-family: 'Roboto', sans-serif;
      position: absolute;
      top: 25%;
      left: 1rem;
    }
  
    .p1-1 {
      font-size: 1.2rem;
      letter-spacing: 0.3px;
      margin-top: 1rem;
      color: #fff;
      text-align: left;
      text-shadow: 0 3px 3px #00000029;
    }
  
    .p2-1 {
      font-size: 3.2rem;
      font-weight: 800;
      color: #fff;
      line-height: 1;
      text-align: left;
      text-shadow: 0 3px 3px #00000029;
      margin-top: 2rem;
      margin-bottom: 0rem;
    }
  
    .p3-1, .p4-1 {
      font-size: 1.2rem;
      color: #fff;
      /* line-height: 1.2; */
      text-align: left;
   
    }
  
    /* Glowing box animation for span */
   
  
    /* Button styling */
    .b1-1 {
      background: none;
      border: 1px solid #ef6101;
      border-radius: 0.9rem;
      color: #fff;
      margin-top: 1rem;
      padding: 0.8rem 1.5rem;
      text-align: left;
      transition: background-color 0.3s ease, color 0.3s ease;
      font-size: 1rem;
    }
  
    .b1-1 a {
      color: #fff;
      font-weight: 600;
      font-size: 1rem;
      text-decoration: none;
      transition: color 0.3s ease;
    }
  
    .b1-1:hover {
      background-color: #fff;
      color: #ef6101;
    }
  
    .b1-1:hover a {
      color: #ef6101;
    }
  
    /* Image container styling */
    .image-container-2 {
      width: 100%;
      height: 30rem;
    }
  
    .image-container-2 img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  
    /* Glowing box keyframes */
    @keyframes glowingBox {
      0%, 100% {
        box-shadow: 0 0 10px rgba(255, 165, 0, 0.5), 0 0 20px rgba(255, 165, 0, 0.5);
        border-color: rgba(255, 165, 0, 0.8);
      }
      50% {
        box-shadow: 0 0 20px rgba(255, 165, 0, 1), 0 0 30px rgba(255, 165, 0, 1);
        border-color: rgba(255, 165, 0, 1);
      }
    }
  }
  