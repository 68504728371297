.Homecomponent5-heading h2{
    color: #b6351b;
    font-family: 'Roboto', sans-serif;
    font-size: 1.7rem;
    letter-spacing: .42px;
    margin-bottom: 2rem;
    margin-left: 3rem;
    opacity: 1;
    text-align: left;
}
@media (max-width: 768px) {
    .Homecomponent5-heading h2{
        color: #b6351b;
        font-family: 'Roboto', sans-serif;
        font-size: 1.7rem;
        letter-spacing: .42px;
        margin-bottom: 2rem;
        margin-left: 3rem;
        opacity: 1;
        text-align: left;
    }
}
