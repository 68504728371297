.careercomp2-main{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  
    font-family: 'Roboto', sans-serif;
}
.carrercomp2-banner{
    display: block;
    unicode-bidi: isolate;
}
.carrercomp2-banner img{
    width: 25rem;
  
}
.carrercomp2-info h1{
    color: #b6351b;
    font-size: 2em;
    font-weight: bold;
}
.carrercomp2-info p{
    width: 35rem;
    font-size: 1rem;
}
@media (max-width: 768px){
    .careercomp2-main{
        align-items: center;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        margin-top: 3rem;
        padding: 1rem;
        width: 93%;
        overflow: hidden;
    }
  
    .carrercomp2-banner img{
        width: 95%;
       
    }
    .carrercomp2-info h1{
        color: #b6351b;
      
    }
    .carrercomp2-info p{
        width: 95%;
        
    } 
}