.viewallcomp1-cont{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.viewallcomp1-button{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}
.viewallcomp1-btn{
    background: none;
    border: 2px solid #b6351b;
    border-radius: .5rem;
    color: #204c79;
    font-weight: 700;
    margin-bottom: 5rem;
    padding: .6rem 2rem;
    transition: all .3s;
}
.viewallcomp1-btn.active {
    background-color:  #204c79;;
    color: white;
    border: 2px solid #204c79;;
}
@media (max-width: 768px) {
    .viewallcomp1-cont {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .viewallcomp1-button {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .viewallcomp1-btn {
        background: none;
        border: 2px solid #b6351b; /* Consistent 2px border */
        border-radius: .5rem;
        color: #204c79;
        font-weight: 700;
        margin-bottom: 1rem;
        padding: .5rem;
        transition: all .3s;
        box-sizing: border-box; /* Ensure borders are included in element size */
        width: 100%; /* Full width for uniformity */
    }
}
