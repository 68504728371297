.slider-container-3{
    overflow: hidden;
    margin-left: 4rem;
 
    padding-bottom: 3rem;
}
.homecomponent8-main{

    width: 100%;
    display: inline-block;

}
.home8-img-cont img{
height: 8rem;
width: 10rem;
}
@media (max-width: 768px){
    .slider-container-3{
        overflow: hidden;
        margin-left: 4rem;
      
        padding-bottom: 3rem;
    }
    .homecomponent8-main{
    
        width: 100%;
        display: inline-block;
       
    }
    .home8-img-cont img{
    height: 6rem;
    width: 4rem;

    }
}