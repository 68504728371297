.viewallcomp2-cont{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.viewallcomp2-card-cont{
    gap: 0;
    padding-bottom: 2rem;
}
.viewallcomp2-card{
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 10px;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    opacity: 1;
    width: 24rem;
}
.viewallcomp2-image-cont img{
    background-size: cover;
    border: 1px solid #707070;
    border-radius: 8px;
    height: 18rem;
    opacity: 1;
    width: 24rem;
}
.viewallcomp2-card-info{
    padding: 1rem;
}
.viewallcomp2-card-info p{
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0;
    opacity: .98;
    text-align: left;
    text-transform: capitalize;
}
.viewallcomp2-card-info h3{
    color: #204c79;
    font-size: 1.4rem;
    margin-bottom: -.4rem;
    margin-top: -.4rem;
}
.viewallcomp2-card-subheading{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}
.viewallcomp2-card-subheading p{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;
    letter-spacing: 0;
    opacity: .98;
    text-align: left;
    text-transform: capitalize;
}
.price-link {
    color: #204c79;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    opacity: .98;
    text-align: left;
    text-decoration: none!important;
    text-transform: capitalize;
}
@media (max-width: 768px) {
    .viewallcomp2-cont{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.viewallcomp2-card-cont{
    gap: 0;
    padding-bottom: 2rem;
}
.viewallcomp2-card{
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 10px;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    opacity: 1;
    width: 100%;
    overflow: hidden;
}
.viewallcomp2-image-cont img{
    background-size: cover;
    border: 1px solid #707070;
    border-radius: 8px;
    height: 18rem;
    opacity: 1;
    width: 100%;
    overflow: hidden;
}
.viewallcomp2-card-info{
    padding: 1rem;
}
.viewallcomp2-card-info p{
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0;
    opacity: .98;
    text-align: left;
    text-transform: capitalize;
}
.viewallcomp2-card-info h3{
    color: #204c79;
    font-size: 1.4rem;
    margin-bottom: -.4rem;
    margin-top: -.4rem;
}
.viewallcomp2-card-subheading{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}
.viewallcomp2-card-subheading p{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;
    letter-spacing: 0;
    opacity: .98;
    text-align: left;
    text-transform: capitalize;
}
}