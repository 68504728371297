.homecomp1-main{
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    width: 100%;
}
.homecomp1-main h1{
    color: #b6351b;
    font-size: 1.8rem;
    letter-spacing: 1px;
    margin-left: 3rem;
    opacity: 1;
    text-align: left;
}
.homecomp1-cont{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.homecomp1-card{
    align-items: center;
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 10px;
    box-shadow: 0 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    height: 14rem;
    opacity: 1;
    padding: 1rem;
    text-align: left;
    width: 11rem;
}
.homecomp1-card-image img{
    padding: 1rem;
    width: 4rem;
}
.homecomp1-card h2{
    color: #204c79;
    font-size: 1.1rem;
    font-weight: 550;
    opacity: .98;
    text-align: left;
    text-transform: capitalize;

}
.homecomp1-card p{
    color: #333;
    font-size: .8rem;
    font-weight: 600;
    margin-top: -.5rem;
    text-align: center;
}
@media (max-width: 768px) {
    .homecomp1-main{
        display: flex;
        flex-direction: column;
        font-family: 'Roboto', sans-serif;
        width: 100%;
    }
    .homecomp1-main h1{
        color: #b6351b;
        font-size: 1.7rem;
        letter-spacing: 1px;
        margin-left: 2rem;
        opacity: 1;
        text-align: left;
    }
    .homecomp1-cont{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-around;
    }
    .homecomp1-card{
        align-items: center;
        background: #fff 0 0 no-repeat padding-box;
        border-radius: 7px;
        box-shadow: 0 3px 6px #00000029;
        display: flex;
        flex-direction: column;
        height: 12rem;
        opacity: 1;
        padding: 1rem;
        text-align: center;
        width: 9rem;
    }
    .homecomp1-card-image img{
        padding: 1rem;
        width: 4rem;
    }
    .homecomp1-card h2{
        color: #204c79;
        font-size: .9rem;
        font-weight: 500;
        opacity: .98;
        text-align: center;
        text-transform: capitalize;
    
    }
    .homecomp1-card p{
        color: #333;
        font-size: .7rem;
        font-weight: 500;
        margin-top: -.5rem;
        text-align: center;
    }
}