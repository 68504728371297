
.Terms-cont {
        display: flex;
        flex-direction: column;
        gap: 0;
        justify-content: center;
        padding: 3rem;
        position: relative;
        text-align: left;
}
.Terms-cont h3{
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
    font-family: 'Roboto',sans-serif;
}
.Terms-cont p{
    font-size: 1.1rem;
    font-family: 'Roboto',sans-serif;
}