.PrivacyPolicy-cont{
        display: flex;
        flex-direction: column;
        gap: 0;
        justify-content: center;
        padding: 3rem;
        position: relative;
        text-align: left;

        
}
.PrivacyPolicy-cont h3{
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
    font-family: 'Roboto',sans-serif;
}

@media (max-width: 768px) {
    .PrivacyPolicy-cont{
        display: flex;
        flex-direction: column;
        gap: 0;
        justify-content: center;
        padding: 3rem;
        position: relative;
        text-align: left;
       

        
}
}