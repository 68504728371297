.contact-cont {
    background: #f5f5f5;
    display: flex;
    flex-direction: row;
    gap: 4rem;
    justify-content: space-around;
    margin-top: 0;
    padding: 5rem 4rem 3rem 5rem;
  
}

.contact-left {
    display: flex;
    flex-direction: column;
}

.contact-left-1 {
    background: #fff;
    border: 0;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
    width: 28rem;
}

.logo {
    border: 1px dashed #000;
    outline: 1px dashed #000;
    outline-offset: 2px;
    padding: 1.8rem;
    position: relative;
}

.logo i {
    color: #204c79;
    font-size: 1.5rem;
    left: 50%;
    position: absolute;
    top: 50%;

    transform: translate(-50%, -50%);
}

.contact-left-info {
    font-size: 1rem;
    font-weight: 600;
}

.contact-address {
    font-size: .8rem;
    width: 20rem;
}

.iframe-cont{
    border: 0;
    height: 21rem;

    transform: translateY(-1rem);
    width: 32rem;
}

@media (max-width: 768px) {
    .contact-cont {
        display: flex;
        flex-direction: column;
        background: #f5f5f5;
        gap: 1rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
        padding: 0;
        width: 100%;
        overflow: hidden;
    }
    
    .contact-left {
        display: flex;
        flex-direction: column;
    }
    
    .contact-left-1 {
        background: #fff;
        border: 0;
        display: flex;
        flex-direction: row;
        gap: 2rem;
        margin-bottom: 1.5rem;
        padding: 1rem;
        width: 22rem;
    }
    
    .logo {
        border: 1px dashed #000;
        outline: 1px dashed #000;
        outline-offset: 2px;
        padding: 1.8rem;
        position: relative;
    }
    
    .logo i {
        color: #204c79;
        font-size: 1.5rem;
        left: 50%;
        position: absolute;
        top: 50%;
      
        transform: translate(-50%, -50%);
    }
    
    .contact-left-info {
        font-size: 1rem;
        font-weight: 600;
    }
    
    .contact-address {
        font-size: .8rem;
        width: 16rem;
    }
    
  
    
    .iframe-cont {
        border: 0;
        height: 13rem;

        transform: translateY(-1rem);
        width: 100vw;
    }
}
