.careercomp4{
    align-items: center;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    font-family: 'Roboto', sans-serif;
}
.careercomp4 h1{
    color: #b6351b;
    
    font-size: 2.2rem;
    font-weight: bold;
}
.carrercomp4-card{
    border: 1px solid #204c79;
    margin: 2rem;
    padding: 2rem 3rem;
    width: 70%;
}
.carrercomp4-card h3{
    color: #204c79;
    font-size: 1.30rem;
    font-weight: bold;
}
.careercomp4-exp{
    display: flex;
    flex-direction: row;
}
.careercomp4-exp p{
    color: #000;
    margin-right: 1rem;
    font-size: 1rem;


}
.careercomp4-exp i{
    color:  #204c79;
    font-weight: 900;
    margin-right: 0.2rem;
}
.carrercomp4-card li{
    display: list-item;
    margin-bottom: 0.25rem;
    font-size: 1.1rem;

}
.careercomp4-btn {
    background: none;
    border: 1.5px solid #204c79;
    margin-top: 1rem;
    padding: 1rem 2rem;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.careercomp4-btn a {
    color: #b6351b;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    transition: color 0.3s ease; /* Smooth transition */
}

/* Hover effect */
.careercomp4-btn:hover {
    background-color: #204c79; /* Blue background */
}

.careercomp4-btn:hover a {
    color: white; /* White text */
}

@media (max-width: 768px){
    .careercomp4{
        align-items: center;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    }
    .careercomp4 h1{
        color: #b6351b;
        
        font-size: 2rem;
        font-weight: bold;
    }
    .carrercomp4-card{
        border: 1px solid #204c79;
        margin: 2rem;
        padding: 2rem 3rem;
        width: 70%;
    }
    .carrercomp4-card h3{
        color: #204c79;
        font-size: 1.20rem;
        font-weight: bold;
    }
    .careercomp4-exp{
        display: flex;
        flex-direction: row;
    }
    .careercomp4-exp p{
        color: #000;
        margin-right: 1rem;
        font-size: 1rem;
    
    
    }
    .careercomp4-exp i{
        color: #000;
        font-size: .8rem;
        margin-right: 1rem;
    }
    .carrercomp4-card li{
        display: list-item;
        margin-bottom: 0.25rem;
             font-size: .9rem;
    
    }
    .careercomp4-btn{
        background: none;
        border: 1.5px solid #204c79;
        margin-top: 1rem;
        padding: 1rem 2rem;
    }
    .careercomp4-btn a{
        color: #b6351b;
        font-size: 1rem;
        font-weight: 600;
        text-decoration: none;
    }
}