.Blogcomp1-cont{
    box-sizing: border-box;
    display: block;
    gap: 1rem;
    row-gap: 1rem;
    column-gap: 1rem;
     margin-bottom: 2rem;
     margin-top: 2rem; 
     padding: 0;
     margin:0;
     padding-top: 2rem;
    
     overflow: hidden;
   
}


.Blogcomp1-heading {
    align-items: center;
    background-color: hsla(0, 0%, 50%, .107);
    color: #204c79;
    display: flex;
    font-family: 'Roboto', sans-serif;
    justify-content: center;
    margin: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    overflow-x: hidden;
   
   
    text-align: center;
    ;
}

    


.Blogcomp1-heading h1{    align-items: center;
    /* background-color: hsla(0, 0%, 50%, .107);  */
    color: #204c79;
    display: flex;
    font-family: 'Roboto', sans-serif;
    justify-content: center;
    text-align: center;

    padding-left: 5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-right: 5rem;
    font-size: 2em; 
     /* margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;*/ 
    font-weight: bold; 
}

.Blogcomp1-img{
    box-sizing: border-box;
        height: 70vh;
       
        width: 100vw;
        padding-top: 5rem;
        padding-bottom: 3rem;
        padding-left: 5rem;
        padding-right: 5rem; 
}
.Blogcomp1-img img {
    
    overflow: clip;
    overflow-y: clip ;
    overflow-x: hidden;
}


.Blogcomp1-paragraph {
    display: block;
    
    unicode-bidi: isolate;
        
        display: flex;
        flex-direction: column;
        gap: 0;
        justify-content: center;
        /* padding: 3rem; */
        position: relative;
        text-align: left;
         padding-left: 5rem;
        padding-right: 5rem; 
        padding-bottom: 2rem;
   
    

}
.Blogcomp1-paragraph h4{
    
        color: #204c79;
        font-family: 'Roboto', sans-serif;
    }
    @media (max-width: 768px){
        .Blogcomp1-cont{
            box-sizing: border-box;
            display: block;
            gap: 1rem;
            row-gap: 1rem;
            column-gap: 1rem;
             margin-bottom: 2rem;
             margin-top: 2rem; 
             padding: 0;
             margin:0;
             padding-top: 2rem;
            
             overflow: hidden;
           
        }
        
        
        .Blogcomp1-heading {
            align-items: center;
            background-color: hsla(0, 0%, 50%, .107);
            color: #204c79;
            display: flex;
            font-family: 'Roboto', sans-serif;
            justify-content: center;
            margin: 0;
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
            overflow-x: hidden;
           
           
            text-align: center;
            ;
        }
        
            
        
        
        .Blogcomp1-heading h1{    align-items: center;
            /* background-color: hsla(0, 0%, 50%, .107);  */
            color: #204c79;
            display: flex;
            font-family: 'Roboto', sans-serif;
            justify-content: center;
            text-align: center;
       
            padding-top: 3rem;
            padding-bottom: 2rem;
            padding-left: 3rem;
            padding-right: 2rem; 
            font-size: 2em; 
             /* margin-block-start: 0.67em;
            margin-block-end: 0.67em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;*/ 
            font-weight: bold; 
        }
        
        .Blogcomp1-img{
            box-sizing: border-box;
                height: 70vh;
               
                width: 100vw;
                padding-top: 3rem;
                padding-bottom: 2rem;
                padding-left: 3rem;
                padding-right: 2rem; 
        }
        .Blogcomp1-img img {
            
            overflow: clip;
            overflow-y: clip ;
            overflow-x: hidden;
        }
        
        
        .Blogcomp1-paragraph {
            display: block;
            
            unicode-bidi: isolate;
                
                display: flex;
                flex-direction: column;
                gap: 0;
                justify-content: center;
                /* padding: 3rem; */
                position: relative;
                text-align: left;
               
                padding-bottom: 2rem;
                padding-left: 3rem;
                padding-right: 2rem; 
            
        
        }
        .Blogcomp1-paragraph h4{
            
                color: #204c79;
                font-family: 'Roboto', sans-serif;
            }
    }
    
