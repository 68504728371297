.about-5-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    position: relative;
    width: 100%;
}
.about-5-container h1{
    color: #b6351b;
    font-family: 'Roboto', sans-serif;
    letter-spacing: .42px;
    margin-left: 3rem;
    opacity: 1;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
}
.about-5-main-container{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-around;
    margin-left: 1rem;
   
}
.about-5-leader-1{
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    text-align: left;
}
.about-5-img1-cont{
    display: block;
    unicode-bidi: isolate;
    
}
.about-5-img1-cont img{
    background-size: cover;
    height: 15rem;
    width: 12rem;

}
.about-5-info-1{
    display: block;
    unicode-bidi: isolate;
    
}
.about-5-info-1 h2{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    margin-bottom: -1rem;
    font-weight: bold;
}
.about-5-info-1 h4{
    color: #234f7b;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.about-5-info-1 p{
    font-family: Helvetica;
    font-size: 1rem;
    width: 25rem;
   
}
.about-5-leader-2{
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    text-align: left;
}
.about-5-img2-cont img{
    background-position: 50%;
    background-size: cover;
    height: 15rem;
    width: 12rem;
}
.about-5-info-2{
    margin-top: -2rem;
}
.about-5-info-2 h2{
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    margin-bottom: -1rem;
    font-weight: bold;
}
.about-5-info-2 h4{
    color: #234f7b;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}
.about-5-info-2 p{
    font-family: Helvetica;
    font-size: 1rem;
    width: 23rem;
}
@media (max-width: 768px){
    .about-5-container{
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        position: relative;
        width: 100%;
    }
    .about-5-container h1{
        color: #b6351b;
        font-family: 'Roboto', sans-serif;
        letter-spacing: .42px;
        margin-left: 3rem;
        opacity: 1;
        text-align: left;
    }
    .about-5-main-container{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: space-around;
        margin-left: 1rem;
        padding: 1rem;
       
    }
    .about-5-leader-1{
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        text-align: left;
    }
    .about-5-img1-cont{
        display: block;
        unicode-bidi: isolate;
        
    }
    .about-5-img1-cont img{
        background-size: cover;
        height: 15rem;
        width: 12rem;
    
    }
    .about-5-info-1{
        display: block;
        unicode-bidi: isolate;
        
    }
    .about-5-info-1 h2{
        color: #000;
        font-family: 'Roboto', sans-serif;
        font-size: 1.3rem;
        margin-bottom: -1rem;
    }
    .about-5-info-1 h4{
        color: #234f7b;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
    }
    .about-5-info-1 p{
        font-family: Helvetica;
        font-size: .9rem;
        width: 22rem;
       
    }
    .about-5-leader-2{
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        justify-content: center;
        text-align: left;;
    }
    .about-5-img2-cont img{
        background-position: 50%;
        background-size: cover;
        height: 15rem;
        width: 12rem;
    }
    .about-5-info-2{
        margin-top: -2rem;
    }
    .about-5-info-2 h2{
        color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
    margin-bottom: -1rem;
    }
    .about-5-info-2 h4{
        color: #234f7b;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
    }
    .about-5-info-2 p{
        font-family: Helvetica;
        font-size: .9rem;
        width: 22rem;
    }
}