.careercomp3-main{
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 2rem;
    width: 100%;
}
.careercomp3-main h1{
    color: #b6351b;
    letter-spacing: 0;
    margin-left: 5rem;
    opacity: 1;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
}
.careercomp3-cont{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.carrer3-1{
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 9rem;
    padding: 1rem;
    width: 11rem;
    z-index: 1;
}
.carrer3-img img{
    border-radius: 50%;
    box-shadow: 1px 1px 2px 1px rgba(70, 96, 143, .5);
    width: 5rem;
}
.career3sub{
    color: #204c79;
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 2rem;
    opacity: .98;
    text-align: center;
    text-transform: capitalize;
    width: 13rem;
}
@media (max-width: 768px){
    .careercomp3-main{
        display: flex;
        flex-direction: column;
        font-family: 'Roboto', sans-serif;
        margin-bottom: 2rem;
        padding: 1rem;
        width: 93%;
    }
    .careercomp3-main h1{
        color: #b6351b;
        letter-spacing: 0;
        margin-left: 0;
        opacity: 1;
        text-align: left;
    }
    .careercomp3-cont{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .carrer3-1{
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 9rem;
        padding: 1rem;
        width: 11rem;
        z-index: 1;
    }
    .carrer3-img img{
        border-radius: 50%;
        box-shadow: 1px 1px 2px 1px rgba(70, 96, 143, .5);
        width: 5rem;
    }
    .career3sub{
        color: #204c79;
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        margin-top: 2rem;
        opacity: .98;
        text-align: center;
        text-transform: capitalize;
        width: 13rem;
    }
}